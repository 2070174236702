import React from "react";
import SEO from "../components/seo";
import Rules from "../components/Rules";

const RulesPage = () => (
  <>
    <SEO title="Rules" />
    <Rules />
  </>
);

export default RulesPage;
