import React from "react";
import styled from "styled-components";

const ListItem = ({ children }) => {
  return (
    <li>
      <p>{children}</p>
    </li>
  );
};

const Strong = styled.strong`
  color: var(--green);
`;

export default function Rules() {
  return (
    <div>
      <ol>
        <ListItem>Comms will be either High or Low.</ListItem>
        <ListItem>Elos will not be mixed.</ListItem>
        <ListItem>
          Whoever is NEXT on the captain spot will determine the elo.
        </ListItem>
        <ListItem>
          If the 2nd person is of a vastly differing elo, they will be skipped
          until a proper match is found.
        </ListItem>
        <ListItem>Games will alternate between High and Low Elo.</ListItem>
        <ListItem>
          New players will not be forced on teams. It willl be optional for
          captains to take a new player for coins.
        </ListItem>
        <ListItem>Tournement draft will be used for every game.</ListItem>
        <ListItem>
          Pick/Ban is the rule of law. No champs guaranteed or limited.
        </ListItem>
        <ListItem>
          If you post your IGN to play, you must be willing to play for either
          captain.
        </ListItem>
        Open to take feedback. DM <Strong>Bustin#0927</Strong> or{" "}
        <Strong>WingOfChicken#5084</Strong> with suggestions.
      </ol>
    </div>
  );
}
